import {
    getFormattedPrices,
    getFormattedSum,
} from '../helpers.js';

const getFormattedFormat = (format) => {
    switch (format) {
        case 'Papier':
            return 'Broché';
        case 'e-book':
            return 'E-book';
        case 'papier-e-book':
            return 'Broché + E-book';
        default:
            return format;
    }
};

const decodeHTMLEntities = (text) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
}

export const bookItemRenderer = (hit, html, defaultCoverUrl) => {

    return html`
        <a href="${hit.productLink}" class="hit-container-book">
            <div class="book-image-wrapper">
                <div class="inner-img-wrapper">
                    <img src="${hit.image || defaultCoverUrl}" alt="${hit.name}" />
                </div>
            </div>
            <div class="book-info-wrapper">
                ${((hit.qualifications && !!hit.qualifications.length) || hit.reference) && html`
                    <div class="book-qualifications-ref-wrapper">
                        <p>
                            <!-- Ref -->
                            ${hit.reference && html`<span class="ref">${hit.reference}</span>`}
                        </p>
                        <!-- Tag -->
                        <div class="qualifications-wrapper">
                            ${hit.qualificationsColors && hit.qualificationsColors.length > 0 && hit.qualificationsColors.map(qualification => {
                                return html`
                                    <span class="tag" style="background-color:${qualification.color} ">
                                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 9.75L12 0.25L-1.90735e-06 9.75L12 9.75Z" fill="#1A1F2F"/>
                                        </svg>
                                        ${qualification.label}
                                    </span>
                                `;
                            })}
                        </div>
                    </div>
                `}
                <div class="block-texts">
                    <h3>${hit.name}</h3>
                    ${hit.description && html`
                        <p class="summary">${decodeHTMLEntities(hit.description)}</p>
                    `}
                    ${hit.auteurs && !!hit.auteurs.length && html`
                        <p class="authors">${decodeHTMLEntities(hit.auteurs.join(', '))}</p>
                    `}
                </div>
                ${hit.prices && !!hit.prices.length && html`
                    <div class="block-prices">
                        ${hit.prices.map(price => html`
                            <div class="price-item">
                                <p class="format">${getFormattedFormat(price.format)}</p>
                                <p class="price-number">${getFormattedPrices(price.price, false, false)?.price}</p>
                            </div>
                        `)}
                    </div>
                `}

            </div>
        </a>
    `;
}

export const authorRenderer = (hit, html) => {
    let initials = '';

    hit.name && (initials = hit.firstName.substring(0, 1));
    hit.firstName && (initials = `${initials}${hit.name.substring(0, 1)}`);

    return html`
        <algolia-hit-author class="hit-container-author ${!!hit.ficheUrl ? 'show-all-biography' : ''}">
            <a href="${hit.ficheUrl || 'javascript:void(0);'}" class="container">
                <div class="heading">
                <div class="picto-wrapper ${hit.image ? '' : 'no-image'} ">
                    ${hit.image && html`
                        <div class="author-image-wrapper">
                            <img src="${hit.image}" alt="${hit.name}" />
                        </div>
                    `}
                    ${initials && html`
                        <p class="initials"><span>${ initials }</span></p>
                    `}
                </div>
                <div class="authors-name-wrapper">
                    <h5>
                        <span class="first-name">${ hit.firstName } </span>
                        <span class="name">${ hit.name }</span>
                    </h5>
                    ${hit.sumary && html`
                        <p>${hit.sumary}</p>
                    `}
                </div>
            </div>
            <p class="biography">${hit.biography}</p>
            <div class="cta-wrapper">
                <button class="cta-button see-more">
                    <span>Voir plus</span>
                    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.75 8L4.25 4.5L0.75 1" stroke="#1A1F2F" stroke-width="1.5"/>
                    </svg>
                </button>
                ${hit.ficheUrl && html`
                    <button data-href="${hit.ficheUrl}" class="cta-button see-sheet">
                        <span>En savoir plus</span>
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.75 8L4.25 4.5L0.75 1" stroke="#1A1F2F" stroke-width="1.5"/>
                        </svg>
                    </button>
                `}
            </div>
            </a>
        </algolia-hit-author>
    `;
}

export const getSearchHits = (container, itemRenderer) => {
    return window.instantsearch.widgets.hits({
        container,
        templates: {
            item (hit, { html, components }) {
                return itemRenderer ? itemRenderer(hit, html) : html`<div></div>`;
            },
            empty (results, { html }) {
                const hasRefinements = results.getRefinements().length > 0
                const description = hasRefinements
                    ? 'Essayez de réinitialiser vos filtres'
                    : 'Veuillez essayer une autre recherche';

                return html`
                <div class="hits-empty-state">
                    <p class="hits-empty-state-title">Aucun résultat trouvé.</p>
                    <p class="hits-empty-state-description">${description}</p>
                </div>
            `
            },
        },
        hitsPerPage: 10
    })
}
