export default class AlgoliaHitAuthor extends HTMLElement {
    constructor() {
        super();
        this.biographyEl = null;
        this.isBiographyDiscoverd = false;
        this.nbLinesbreakpoints = [
            {
                minRes: 768,
                nbLines: 3
            },
            {
                minRes: 0,
                nbLines: 8
            }
        ];
    }

    onResizeObserverHandler(pArr) {
        const targetEl = pArr[0];
        if (targetEl && targetEl.contentRect) {
            const height = Math.ceil(targetEl.contentRect.height);
            const scrollHeight = targetEl.target.scrollHeight;

            this.classList.toggle('has-show-more-button', (height ? scrollHeight > height : false));
        }
    }

    /**
     * Initialisation
     */
    init() {
        this.biographyEl = this.querySelector('p.biography');
        const btnSeeMore = this.querySelector('button.see-more');
        const btnSheet = this.querySelector('button.see-sheet');

        if (this.biographyEl) {
            new ResizeObserver((observables) => { this.onResizeObserverHandler(observables); }).observe(this.biographyEl)
        }

        if (btnSeeMore) {
            btnSeeMore.addEventListener('click', (evt) => {
                evt.preventDefault();
                this.classList.add('show-all-biography');
            })
        }

        if (btnSheet) {
            const href = btnSheet.getAttribute('data-href');
            if (href) {
                btnSheet.addEventListener('click', (evt) => {
                    evt.preventDefault();
                    window.location.href = href;
                });
            }
        }
    }

    connectedCallback() {
        setTimeout(() => {
            this.init();
        }, 100);
    }
}
