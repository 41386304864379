import {
    CollectionsHelpers
} from '../helpers.js'

const collectionsHelpers = new CollectionsHelpers();

const getParsedParamsValue = (value) => {
    if (value) {
        return [value];
    }

    return [];
}

// Routestate -> URL
const getCreateURL = (indexName, basePath) => {
    return ({ qsModule, routeState, location }) => {
        const { origin } = location;
        const queryParameters = { };
        const baseUrl = `${origin}/${basePath}`;

        let queryString = '';
        let pathname = `${baseUrl}`;


        // Collections
        // console.log('getCreateURL > routeState: ', routeState)
        if (!!routeState.collection) {
            pathname = `${baseUrl}/${routeState.collection.slug}`;
        }

        // Add other refinements
        Object.keys(routeState).forEach(key => {
            if (key !== 'collection') {
                const value = routeState[key];
                const hasValue = Array.isArray(value) ? !!value.length : !!value;

                if (hasValue) {
                    if (key === 'page') {
                        try {
                            const num = parseInt(value, 10);

                            num > 1 && (queryParameters['pagenum'] = num);
                        } catch (e) {
                            console.log('Erro while parsing page number', e);
                        }
                    } else {
                        queryParameters[key] = routeState[key];
                    }
                }
            }
        });

        if (Object.keys(queryParameters).length) {
            queryString = `${qsModule.stringify(queryParameters, {
                addQueryPrefix: true,
                arrayFormat: 'comma',
                encode: false
            })}` ;

            !pathname.endsWith('/') && (queryString = `/${queryString}`);
        }

        // console.log('----- createUrl : pathname: ', pathname , ' resultUrl -----', `${pathname}${queryString}`);
        return `${pathname}${queryString}`;
    }
}

// URL -> Routestate
const getParseURL = (indexName, basePath) => {
    return ({ qsModule, location }) => {
        const { pathname, search } = location;
        const queryParameters = qsModule.parse(search.slice(1), {
            comma: true
        });
        const {
            query = '',
            pagenum = '1',
            auteurs,
            formats,
            qualifications,
            themes,
            formateurs = ""
        } = queryParameters;

        const allAuthors = Array.isArray(auteurs) ? auteurs.filter(Boolean) : getParsedParamsValue(auteurs);
        const allFormats = Array.isArray(formats) ? formats.filter(Boolean) : getParsedParamsValue(formats);
        const allQualifications = Array.isArray(qualifications) ? qualifications.filter(Boolean) : getParsedParamsValue(qualifications);
        const allThemes = Array.isArray(themes) ? themes.filter(Boolean) : getParsedParamsValue(themes);

        // Search collection slug in pathname
        // We suppose that it starts with basePath
        const splitPathname = pathname.split('/').filter(path => path !== basePath).filter(Boolean);
        const collectionObj = !!splitPathname.length ? collectionsHelpers.getCollectionObj(splitPathname[0].toLowerCase()) : null;

        const routeState = {
            query: decodeURIComponent(query) || '',
            page: `${pagenum}`,
            collection: collectionObj,
            auteurs: allAuthors,
            formats: allFormats,
            qualifications: allQualifications,
            themes: allThemes,
            formateurs: formateurs ? decodeURIComponent(formateurs) : null
        };

        // console.log('--- parseUrl : auteurs: ', decodeURIComponent(auteurs),  ' routeState ---', routeState);

        return routeState;
    }
};

// UIState -> Routestate
const getStateToRoute = (indexName) => {
    return (uiState) => {
        const indexUiState = uiState[indexName] || {};
        const routeState = {
            query: indexUiState.query || '',
            page: indexUiState.page || '1',
            collection: null,
            themes: [],
            auteurs: [],
            formats: [],
            qualifications: [],
            formateurs: null
        };
        const refinementList = indexUiState.refinementList;
        const menu = indexUiState.menu;

        // Check if we have collection
        if (indexUiState.menu && indexUiState.menu.collections) {
            routeState.collection = collectionsHelpers.getCollectionObj(indexUiState.menu.collections) ?? null;
        }

        if (refinementList) {
            // Themes
            if (Array.isArray(refinementList.themes)) {
                routeState.themes = refinementList.themes;
            }

            // Auteurs
            if (Array.isArray(refinementList.auteurs)) {
                routeState.auteurs = refinementList.auteurs;
            }

            // Formats
            if (Array.isArray(refinementList.formats)) {
                routeState.formats = refinementList.formats
            }

            // Qualifications
            if (Array.isArray(refinementList.qualifications)) {
                routeState.qualifications = refinementList.qualifications;
            }
        }

        // Formateurs
        if (indexUiState.menu && indexUiState.menu.formateurs) {
            routeState.formateurs = indexUiState.menu.formateurs
        }

        // console.log('----- stateToRoute : uiState -----', uiState, ' routeState -----', routeState);

        return routeState;
    };
}

// Routestate -> UIState
const getRouteToState = (indexName) => {
    return (routeState) => {
        const uiState = {
            [indexName] : {
                configure: { hitsPerPage: 10 },
                query: routeState.query || '',
                page: routeState.page || '1',
                menu: null,
                refinementList: {
                    auteurs: [],
                    formats: [],
                    themes: [],
                    qualifications: []
                }
            }
        };
        const shState = uiState[indexName]; // shorthand
        const refinementList = shState.refinementList;

        // Collections
        if (!!routeState.collection) {
            shState.menu = { collections: routeState.collection.label}
        }

        // Themes
        refinementList.themes = routeState.themes;
        refinementList.auteurs = routeState.auteurs;
        refinementList.formats = routeState.formats;
        refinementList.qualifications = routeState.qualifications;

        if (routeState.formateurs) {
            shState.menu = shState.menu || {};
            shState.menu = {
                ...shState.menu,
                formateurs: routeState.formateurs
            }
        }

        // console.log('----- routeToState : routeState -> ', routeState , ' uiState ----->', uiState);
        return uiState;
    };
}

const getRouter = (indexName, basePath) => {
    return window.instantsearch.routers.history({
        createURL: getCreateURL(indexName, basePath),
        parseURL: getParseURL(indexName, basePath)
    })
};


const getStateMapping = (indexName) => {
    return {
        stateToRoute: getStateToRoute(indexName),
        routeToState: getRouteToState(indexName)
    }
}

export function getRouting(indexName, collections = [], basePath) {
    collectionsHelpers.init(collections);

    return {
        router: getRouter(indexName, basePath),
        stateMapping: getStateMapping(indexName),
    };
}
