window.$ = jQuery;

import '../styles/app.scss';

import Router from './util/Router';
import tmlActionLogin from '../../../ponts-formations/resources/scripts/routes/tml-action-login.js';
import tmlAction from '../../../ponts-formations/resources/scripts/routes/tml-action.js';
import common from './routes/common';
import AlgoliaSearch from './components/algolia-search/algolia-search.js';
import AlgoliaHitAuthor from './components/algolia-search/algolia-hit-author.js';
import AlgoliaHitAuthorBiography from './components/algolia-search/algolia-hit-author-biography.js';


/** Populate Router instance with DOM routes */
const routes = new Router({
    common,
    tmlActionLogin,
    tmlAction
});

// Load Events
$(document).ready(() => routes.loadEvents());

// Custom elements
customElements.define('algolia-search', AlgoliaSearch);
customElements.define('algolia-hit-author', AlgoliaHitAuthor);
customElements.define('algolia-hit-author-biography', AlgoliaHitAuthorBiography);
