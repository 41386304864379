export const getLocalMonth = (date) => {
    if (date) {
        const monthLabel = date.toLocaleString('default', { month: 'long' });

        return  `${monthLabel[0].toUpperCase()}${monthLabel.substring(1)}`;
    }

    return '';
}


export const getCourseDuration = (hourDuration, dayDuration) => {
    if (dayDuration) {
        const qty = parseFloat(dayDuration);

        if (qty) {
            return `${qty} jour${qty > 1 ? 's' : ''}`;
        }
    }

    if (hourDuration) {
        const qty = parseFloat(hourDuration);

        if (qty) {
            return `${qty} heure${qty > 1 ? 's' : ''}`;
        }
    }

    return false;
}

export const getFormattedSum = (montant) => {
    let [partieEntiere, partieDecimale] = montant.toString().split('.');

    partieEntiere = partieEntiere.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return partieDecimale ? `${partieEntiere},${partieDecimale}` : partieEntiere;
}

export const getFormattedPrices = (price, discountPrice, isHt = true) => {
    const htTerm = isHt ? 'HT' : '';

    if (price || discountPrice) {
        const result = {};

        if (price) {
            result.price = `${getFormattedSum(parseFloat(price).toFixed(2))} € ${htTerm}`
        }

        if (discountPrice) {
            result.discountPrice = `${getFormattedSum(parseFloat(discountPrice).toFixed(2))} € ${htTerm}`
        }

        return result;
    }

    return false;
}

export const getFormattedDate = (date) => {
    if (date) {
        try {
            const dateInst = date instanceof Date ? date : new Date(date);
            const day = String(dateInst.getDate()).padStart(2, '0');
            const month = String(dateInst.getMonth() + 1).padStart(2, '0');
            const year = dateInst.getFullYear();

            return `${day}/${month}/${year}`;
        } catch (e) {
            console.log('Error in getFormattedDate:: ', e);
        }
    }

    return false;
}

export const getIntervalDateLiteral = (startDateTs, endDateTs) => {
    const startDateStr = getFormattedDate(startDateTs);
    const endDateStr = getFormattedDate(endDateTs);

    if (startDateStr && endDateStr) {
        return `du ${startDateStr} au ${endDateStr}`;
    }

    if (endDateStr) {
        return `jusqu'au ${endDateStr}`;
    }

    if (startDateStr) {
        return `à partir du ${startDateStr}`;
    }

    return '';
}


export const isEmptyObject = (obj) => {
    try {
        return !!Object.keys(obj).length;
    } catch (e) {
        console.warn('check isEmptyObject: ', e);
    }
}

export const getCleanThematicLabel = (label) => {
    const thematicSeparator = '§';

    if (label && `${label}`.includes(thematicSeparator)) {
        const cleaned = label.split(thematicSeparator)[1];

        if (cleaned) {
            return `${cleaned}`.trim();
        }
    }

    return label;
}

export class CollectionsHelpers {
    constructor () {
        this.allCollection = [];
        this.basePath = '';
    }

    init(allCollection, basePath) {
        this.allCollection = allCollection;
        this.basePath = basePath;
    }

    getSplitPathname(pathname) {
        if (pathname) {
            return Array.from(new Set(`${pathname}`.split('/'))).map(slug => slug !== this.basePath ? slug : '').filter(Boolean);
        }

        return [];
    }

    getCollectionObj(token) {
        return this.allCollection.find(coll => {
            return coll.label === token || coll.slug === token;
        });
    }
}

export function handleWindowResize() {
    const headerEl = document.querySelector('header#pf-header');
    const bounds = headerEl ? headerEl.getBoundingClientRect() : {};

    bounds.height && document.documentElement.style.setProperty('--main-header-height', `${bounds.height}px`);
}

export function initHeaderHeightUpdater(callback) {
    window.addEventListener('resize', () => {
        handleWindowResize();
        callback && callback();
    });
}
