// import StickyBlock from '../../../ponts-formations/resources/scripts/components/sticky-block';

import StickyBlock from '../components/sticky-block';
import Header from "../components/header";
import SingleProductPresse from '../components/single-product-presse';
import CarouselCtaExtended from '../../../../ponts-formations/resources/scripts/components/carouselCtaExtended';
import Videos from '../../../../ponts-formations/resources/scripts/components/videos';
import CarouselBlocks from '../components/carousel-blocks';
import TabsWithSwipers from '../components/TabsWithSwipers';
import VoirPlusText from '../../../../ponts-formations/resources/scripts/components/voir-plus-text';
import VoirPlusImageText from '../../../../ponts-formations/resources/scripts/components/voir-plus-text-image';
import Temoignage from '../../../../ponts-formations/resources/scripts/components/temoignage';
import KeyNumbers from '../../../../ponts-formations/resources/scripts/components/KeyNumbers';
import KeyNumbersVisual from '../../../../ponts-formations/resources/scripts/components/keynumbervisual';
import Clients from '../../../../ponts-formations/resources/scripts/components/clients';
export default {
    init() {
      console.log('init js press');
    },

    finalize() {
        let header = new Header();
        let stickyBlock = new StickyBlock();
        let singleProductPresse = new SingleProductPresse();
        let carouselCtaExtended = new CarouselCtaExtended();
        let videos = new Videos();
        let carouselBlock = new CarouselBlocks();
        let tabsWithSwipers = new TabsWithSwipers();
        let voirPlusText = new VoirPlusText();
        let voirPlusImageText = new VoirPlusImageText();
        let temoignage = new Temoignage();
        let keyNumbers = new KeyNumbers();
        let keyNumbersVisual = new KeyNumbersVisual;
        let clients = new Clients();
        videos.init();
        header.init();
        singleProductPresse.init();
        voirPlusText.init();
        voirPlusImageText.init();
        keyNumbers.init();
        console.log('finalize common.js--')
    },
};
