import { collapseButtonText } from './panel.js';

export const getMenuFilter = (container, attribute) => {
    const config = {
        container,
        attribute,
        sortBy: ['name'],
        limit: 100
    }

    return window.instantsearch.widgets.menu(config);
}

export const getMenuFilterWithPanel = (container, attribute, title) => {
    let menuWithPanel = null;
    const config = {
        container,
        attribute,
        sortBy: ['name'],
        limit: 100
    }

    if (title) {
        menuWithPanel = window.instantsearch.widgets.panel({
            templates: {
                header() {
                    return `${title}`;
                },
                collapseButtonText,
            },
            collapsed: () => false,
        })(window.instantsearch.widgets.menu);

        return menuWithPanel(config);
    }

    return window.instantsearch.widgets.menu(config);
}
