export default class AlgoliaHitAuthorBiography extends HTMLElement {
    constructor() {
        super();
        this.loaded = false;
        this.checkEvent = new CustomEvent("load", {
            bubbles: true,
            cancelable: false,
            composed: true
        });
    }

    /**
     * Initialisation
     */
    init() {
        if (!this.loaded) {
            this.dispatchEvent(this.checkEvent);
            this.loaded = true
        }
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 1500 );
    }
}
