export default class SingleProductPresse {
    constructor() {}

    init() {
        console.log('single-product-presse.init');
        const $variations = $('.variations-item', '.variations');

        if ($variations.length > 0) {
            $variations.each((index, element) => {

                if(0 == parseInt($(element).data('variation'))) {
                    $(element).addClass('active');
                    this.selectVariation($(element));
                }

                $(element).on('click', (e) => {
                    $('.variations-item').removeClass('active');
                    $(element).addClass('active');
                    this.selectVariation($(element));
                });


            });
        }

        this.stickyRegisterButtonHandler();
        this.initializeStickyNav();
        this.initializeMaillageTabs();
        if (document.readyState === 'complete') {
            this.initializeVoirPlus();
        } else {
            window.addEventListener('load', () => {
                this.initializeVoirPlus();
            });
        }
    }

    /**
     * Initialise la logique "Voir Plus"
     */
    initializeVoirPlus() {
        const intervenants = document.querySelectorAll('.intervenant-item');

        if (intervenants.length) {
            intervenants.forEach((intervenant) => {
                const showMoreButton = intervenant.querySelector('.show-more-btn');
                const description = intervenant.querySelector('.intervenant-description');
                const enSavoirPlusButton = intervenant.querySelector('.en-savoir-plus-btn');

                const adjustDescription = () => {
                    if (!description) return; // Ajout d'une vérification pour éviter les erreurs

                    if (description.scrollHeight > description.clientHeight) {
                        if (showMoreButton) showMoreButton.style.display = 'flex';
                        if (enSavoirPlusButton) enSavoirPlusButton.style.display = 'none';
                    } else {
                        if (showMoreButton) showMoreButton.style.display = 'none';
                        if (enSavoirPlusButton) enSavoirPlusButton.style.display = 'block';
                    }
                };

                if (description) adjustDescription();

                if (showMoreButton) {
                    showMoreButton.addEventListener('click', () => {
                        if (!description.classList.contains('expanded')) {
                            description.classList.add('expanded');
                            showMoreButton.style.display = 'none';
                            if (enSavoirPlusButton) enSavoirPlusButton.style.display = 'block';
                        }
                    });
                }

                window.addEventListener('resize', adjustDescription);
            });
        }
    }



    /**
     * Sélectionne une variation
     */
    selectVariation($variation) {
        const $btnCommander = $('.btn-add-to-cart');
        const variationSlug = $variation.data('variation-slug');
        const selectedFormat = $variation.find('.format').text();

        $btnCommander.attr('href', mainBlogUrl + 'panier/?add-to-cart=' +        $btnCommander.data('product-id') + '&format=' + variationSlug);

        $('.format-product-aside').text(selectedFormat);
    }

    /**
     * Gère l'affichage du bouton sticky
     */
    stickyRegisterButtonHandler() {
        const $btnPreIncription = $('.single-product-aside-item .btn-add-to-cart');
        const $stickyButton = $('#sticky-commander');
        $stickyButton.hide();

        if ($btnPreIncription.length && $stickyButton.length) {
            function checkSidebarPosition() {
                const targetPosition = $btnPreIncription[0].getBoundingClientRect().top;

                if (targetPosition < 0) {
                    $stickyButton.show();
                } else {
                    $stickyButton.hide();
                }
            }

            function scrollToSidebar(event) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $btnPreIncription.offset().top - 150
                }, 300);
            }

            $(window).on('scroll', checkSidebarPosition);
            $stickyButton.on('click', scrollToSidebar);

            checkSidebarPosition();
        }
    }

    /**
     * Initialise la navigation sticky pour les produits presse
     */
    initializeStickyNav() {
        const $navLinks = $('.product-presse-sticky-nav a');
        const $stickyNav = $('.product-presse-sticky-nav');

        $navLinks.each(function () {
            $(this).parent('li').hide(); // Cache tous les <li> au départ

            const targetId = $(this).attr('id')?.replace('#', '');
            if (targetId && $(`#${targetId}`).length > 0) {
                $(this).parent('li').show(); // Affiche seulement ceux ayant un target valide
            }
        });


        const visibleLinks = $navLinks.filter(':visible');
        if (visibleLinks.length === 0) {
            $stickyNav.hide();
            return;
        }

        const firstVisibleLink = visibleLinks.first();
        const firstTargetId = firstVisibleLink.attr('id').replace('#', '');
        $(`#${firstTargetId}`).show();
        firstVisibleLink.addClass('active');

        visibleLinks.on('click', (event) => {
            event.preventDefault();

            const targetId = $(event.currentTarget).attr('id').replace('#', '');

            $('.single-product-body-section').hide();
            $(`#${targetId}`).fadeIn();

            $navLinks.removeClass('active');
            $(event.currentTarget).addClass('active');

            // Réinitialiser initializeVoirPlus
            this.initializeVoirPlus();
        });
    }


    /**
     * Initialise le système de tabs pour la navigation maillage
     */
    initializeMaillageTabs() {
        const $navLinks = $('.navigation-maillage a');

        $('.product-ouvrage').hide();

        const $firstLink = $navLinks.first();
        if ($firstLink.length) {
            const firstTargetId = $firstLink.attr('id').replace('#', '');
            $(`#${firstTargetId}`).show();
            $firstLink.addClass('active');
        }

        $navLinks.on('click', function (event) {
            event.preventDefault();

            const targetId = $(this).attr('id').replace('#', '');

            $('.product-ouvrage').hide();
            $(`#${targetId}`).fadeIn();

            $navLinks.removeClass('active');
            $(this).addClass('active');
        });
    }

}
