export default class Header {
    constructor() {
        this.navItems = document.querySelectorAll('.banner-principal-nav-item');
        this.$btnHumberger = $('.menu-burger');
        this.$bannerPrincipal = $('.banner-principal');
        this.$bannersubMenu = $('.banner-submenu');
        this.$backLinks = $('.submenu-back-link');
        this.lastInteractionWasKeyboard = false;
        this.$searchInput = $('#banner-search');
        this.$searchButton = $('.search-button');
    }

    init() {
        this.bindEvents();
        this.handleOutsideClick();
        this.$btnHumberger.on('click', (event) => this.toggleMenuPrincipal(event));
        this.$backLinks.on('click', (event) => this.handleBackLinkClick(event));
        this.bindSearchEvents();


        document.addEventListener('keydown', () => {
            this.lastInteractionWasKeyboard = true;
        });

        document.addEventListener('mousedown', () => {
            this.lastInteractionWasKeyboard = false;
        });

        $('.popin-form .btn-close').on('click', (e) => {
            $(e.currentTarget).closest('.popin-form-open').removeClass('popin-form-open');
            $('html, body').removeClass('popin-open')
            return false;
        });

        this.showPopinSwitch();

    }



    bindSearchEvents() {
        $(document).on('input', '#banner-search', (e) => {

            const inputValue = $(e.target).val();
            if (inputValue.trim()) {
                console.log(this.$searchButton);
                this.$searchButton.addClass('show');
            } else {
                this.$searchButton.removeClass('show');
            }
        });
    }

    bindEvents() {
        this.navItems.forEach((item) => {
            const navLink = item.querySelector('.banner-principal-nav-link');
            const submenu = item.querySelector('.banner-submenu');
            const submenuChildren = item.querySelector('.submenu-children');
            const submenuList = item.querySelector('.submenu-list');
            const submenuItems = item.querySelectorAll('.submenu-item');
            const submenuChildrenItems = item.querySelectorAll('.submenu-children-item');

            if (submenu) {
                navLink.addEventListener('click', () => {
                    this.handleSubmenuActivation(item, submenu, submenuChildren, submenuList, submenuItems, submenuChildrenItems);
                });

                navLink.addEventListener('focus', () => {
                    if (this.lastInteractionWasKeyboard) {
                        this.handleSubmenuActivation(item, submenu, submenuChildren, submenuList, submenuItems, submenuChildrenItems);
                    }
                });
            }

            submenuItems.forEach((submenuItem) => {
                submenuItem.addEventListener('click', (event) => {
                    if (submenuItem.tagName === 'A' && submenuItem.getAttribute('href') !== '#') {
                        return;
                    }

                    this.handleSubmenuItemActivation(submenuItem, submenuItems, submenuChildrenItems, submenuChildren, submenuList);
                });

                submenuItem.addEventListener('focus', () => {
                    if (this.lastInteractionWasKeyboard) {
                        if (submenuItem.tagName !== 'A') {
                            this.handleSubmenuItemActivation(submenuItem, submenuItems, submenuChildrenItems, submenuChildren, submenuList);
                        }
                    }
                });
            });

        });
    }

    handleSubmenuActivation(item, submenu, submenuChildren, submenuList, submenuItems, submenuChildrenItems) {
        const isOpen = submenu.classList.contains('open');
        this.closeAllSubmenus();

        if (!isOpen) {
            submenu.classList.add('open');
            item.classList.add('active');
        }
    }

    handleSubmenuItemActivation(submenuItem, submenuItems, submenuChildrenItems, submenuChildren, submenuList) {
        submenuItems.forEach(s => s.classList.remove('active'));
        submenuChildrenItems.forEach(child => child.classList.remove('active'));

        submenuItem.classList.add('active');
        const activeSubmenuId = submenuItem.getAttribute('data-submenu-item-id');

        if (activeSubmenuId) {
            document.getElementById(activeSubmenuId).classList.add('active');
        }

        if (submenuChildren) {
            submenuChildren.classList.add('open');
        }

        if (submenuList) {
            submenuList.classList.add('active');
        }
    }

    closeAllSubmenus() {
        this.navItems.forEach((item) => {
            const submenu = item.querySelector('.banner-submenu');
            const submenuChildren = item.querySelector('.submenu-children');
            const submenuItems = item.querySelectorAll('.submenu-item');
            const submenuChildrenItems = document.querySelectorAll('.submenu-children-item');
            const activeSubmenuItems = document.querySelectorAll('.submenu-children .active');
            const activeSubmenuListItems = document.querySelectorAll('.submenu-list');

            if (submenu) {
                submenu.classList.remove('open');
                item.classList.remove('active');
            }

            if (submenuChildren) {
                submenuChildren.classList.remove('open');
            }

            submenuItems.forEach((submenuItem) => {
                submenuItem.classList.remove('active');
            });

            submenuChildrenItems.forEach((submenuChildrenItem) => {
                submenuChildrenItem.classList.remove('active');
            });

            activeSubmenuItems.forEach((activeItem) => {
                activeItem.classList.remove('active');
            });

            activeSubmenuListItems.forEach((activeListItem) => {
                activeListItem.classList.remove('active');
            });
        });
    }

    handleOutsideClick() {
        document.addEventListener('click', (e) => {
            let clickedInside = false;

            this.navItems.forEach((item) => {
                if (item.contains(e.target)) {
                    clickedInside = true;
                }
            });

            if (!clickedInside) {
                this.closeAllSubmenus();
            }
        });
    }

    toggleMenuPrincipal(event) {
        event.preventDefault();
        this.$btnHumberger.toggleClass('open');
        this.$bannerPrincipal.toggleClass('open');
        $('html, body').toggleClass('open');
    }

    handleBackLinkClick(event) {
        event.preventDefault();
        this.$bannersubMenu.removeClass('open');
    }

    /**
     * show switch to blog popin
     */

    showPopinSwitch() {
        const $cardPress = $('.btn-add-to-cart, .connexion-link');
        const $btnConfirm = $('.btn-confirm');
        let $popin = $('.popin-switch.popin-commande');
        let targetLink = null;

        $cardPress.each((index, card) => {
            $(card).on('click', (e) => {
                e.preventDefault();

                targetLink = e.currentTarget.href;
                console.log(targetLink);

                $btnConfirm.attr('href', targetLink);
                if ($(e.currentTarget).hasClass('connexion-link')) {
                    $popin = $('.popin-switch.popin-connexion');
                    $btnConfirm.attr('target', '_blank');
                }
                $popin.addClass('popin-form-open');
                $('html, body').addClass('popin-open');
                return false;
            });
        });
    }

}
