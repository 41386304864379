export default class TabsWithSwipers {
    constructor() {
        this.carouselCtas = document.querySelectorAll('.fd-block-carousel-cta');
        this.init();
    }

    init() {
        this.initializeTabs();
        this.initSwiperTabs();
    }

    initializeTabs() {
        $(document).on('click', '.fd-block-carousel-cta .tabs li', function (e) {
            var $tab = $(this);
            var $tabContent = $tab.closest('.fd-block-carousel-cta').find('.tabs-content');
            var targetTabId = $tab.data('tab');
    
            $tab.closest('.fd-block-carousel-cta').find('.tabs li').removeClass('active');
            $tab.addClass('active');
    
            $tabContent.find('.tab-content').removeClass('active');
            $tabContent.find(`#${targetTabId}`).addClass('active');
        });
    
        this.carouselCtas.forEach(function (elt) {
            const $tabs = $(elt).find('.tabs li');
            const $tabsContent = $(elt).find('.tabs-content > .tab-content');
    
            $tabs.each(function () {
                const tabId = $(this).data('tab');
                const $relatedContent = $tabsContent.filter(`#${tabId}`);
                if (!$relatedContent.text().trim()) {
                    $(this).remove(); 
                    $relatedContent.remove(); 
                }
            });
    
            $tabsContent.removeClass('active');
    
            const $validTabs = $(elt).find('.tabs li');
            const $validTabsContent = $(elt).find('.tabs-content > .tab-content');
    
            if ($validTabs.length) {
                $validTabs.first().addClass('active');
                $validTabsContent.first().addClass('active');
            }
        });
    }
    

    initSwiperTabs() {
        if (this.carouselCtas.length) {
            this.carouselCtas.forEach((elt, parentIndex) => {
                const swiperElementElearning = elt.querySelectorAll('.e-learning-list .swiper-carousel-cta, .presse-list .swiper-carousel-cta');
                const swiperElementFormation = elt.querySelectorAll('.formation-list .swiper-carousel-cta');
            
                swiperElementElearning.forEach((item, i) => {
                    const pagination = item.querySelector('.swiper-pagination');
                    if (!pagination) return;
            
                    pagination.id = `swiper-pagination-cta-${parentIndex}-${i}`;
            
                    const swiper = new Swiper(item, {
                        slidesPerView: 1.01,
                        centeredSlides: false,
                        spaceBetween: 5,
                        grabCursor: true,
                        dynamicBullets: true,
                        pagination: {
                            el: `#swiper-pagination-cta-${parentIndex}-${i}`,
                            type: 'bullets',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: item.querySelector('.swiper-next'),
                            prevEl: item.querySelector('.swiper-prev'),
                        },
                        breakpoints: {
                            768: {
                                slidesPerView: 2.01,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3.01,
                                spaceBetween: 20,
                            },
                            1280: {
                                slidesPerView: 4.01,
                                spaceBetween: 20,
                            },
                        },
                    });
            
                    swiper.on('slideChange', function () {
                        const activeIndex = swiper.realIndex;
                        const bullets = pagination.querySelectorAll('.swiper-pagination-bullet');
            
                        if (bullets.length) {
                            bullets.forEach((bullet) => bullet.classList.remove('swiper-pagination-bullet-active'));
            
                            if (bullets[activeIndex]) {
                                bullets[activeIndex].classList.add('swiper-pagination-bullet-active');
                            }
                        }
                    });
                });
            
                swiperElementFormation.forEach((item, i) => {
                    const pagination = item.querySelector('.swiper-pagination');
                    if (!pagination) return;
            
                    pagination.id = `swiper-pagination-formation-${parentIndex}-${i}`;
            
                    const swiper = new Swiper(item, {
                        slidesPerView: 1.01,
                        centeredSlides: false,
                        spaceBetween: 5,
                        grabCursor: true,
                        dynamicBullets: true,
                        pagination: {
                            el: `#swiper-pagination-formation-${parentIndex}-${i}`,
                            type: 'bullets',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: item.querySelector('.swiper-next'),
                            prevEl: item.querySelector('.swiper-prev'),
                        },
                        breakpoints: {
                            768: {
                                slidesPerView: 2.01,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3.01,
                                spaceBetween: 20,
                            },
                        },
                    });
            
                    swiper.on('slideChange', function () {
                        const activeIndex = swiper.realIndex;
                        const bullets = pagination.querySelectorAll('.swiper-pagination-bullet');
            
                        if (bullets.length) {
                            bullets.forEach((bullet) => bullet.classList.remove('swiper-pagination-bullet-active'));
            
                            if (bullets[activeIndex]) {
                                bullets[activeIndex].classList.add('swiper-pagination-bullet-active');
                            }
                        }
                    });
                });
            });
            
        }
    }
    
}
