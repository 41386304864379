import { collapseButtonText } from './panel.js';

export const getRefinementList = (container, attribute, title) => {
    let qualificationRefinementList = null;
    const config = {
        container,
        attribute,
        sortBy: ['name'],
        limit: 100
    }

    if (title) {
        qualificationRefinementList = window.instantsearch.widgets.panel({
            templates: {
                header() {
                    return `${title}`;
                },
                collapseButtonText,
            },
            collapsed: () => false,
        })(window.instantsearch.widgets.refinementList);

        return qualificationRefinementList(config)
    }

    return window.instantsearch.widgets.refinementList(config);
}
