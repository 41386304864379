import { getSearchWidget } from '../../../../../ponts-formations/resources/scripts/components/algolia-search/widgets/search-box.js';
import { getMenuFilter, getMenuFilterWithPanel } from './widgets/filter-menu.js';
import { getRefinementList } from './widgets/refinement-list.js';
import { getConfigureWidget } from './widgets/configure.js';
import { getStatsWidget } from '../../../../../ponts-formations/resources/scripts/components/algolia-search/widgets/stats.js';
import { getPagination } from '../../../../../ponts-formations/resources/scripts/components/algolia-search/widgets/pagination.js';
import { getCurrentRefinementsWidget } from './widgets/current-refinements.js';
import {
    bookItemRenderer,
    authorRenderer, getSearchHits
} from './widgets/search-hits.js'
import { initHeaderHeightUpdater, handleWindowResize } from './helpers.js';
import { getRouting } from './widgets/presse-routing.js';

export default class AlgoliaSearch extends HTMLElement {
    constructor() {
        super();
        this.firstRender = false;
        this.currentRefinementEl = null;
        this.currentRefinementElMobile = null;
        this.search = null;
        this.filtersContainerEl = null;
        this.inputSearch = null;
        this.indexName = '';
        this.lastCollectionState = false;
        this.defaultCoverUrl = '';
        this.paginationScrollToEl = false;
    }

    statsTextGenerator(html, data, itemLabel = 'auteur') {
        return html`${data.nbHits} ${itemLabel}${data.nbHits > 1 && 's'}`;
    }

    buildWidgets (searchInst, postType) {
        const searchBoxEl = this.querySelector('[data-widget="searchbox"]');
        const collectionsFilterEl = this.querySelector('[data-widget="collections"]');
        const thematicsFilterEl = this.querySelector('[data-widget="thematics"]');
        const subThematicsFilterEl = this.querySelector('[data-widget="sub-thematics"]');
        const formatsFilterEl = this.querySelector('[data-widget="formats"]');
        const trainersFilterEl = this.querySelector('[data-widget="trainer"]');
        const authorsFilterEl = this.querySelector('[data-widget="authors"]');
        const qualificationsFilterEl = this.querySelector('[data-widget="qualifications"]');
        const paginationEl = this.querySelector('[data-widget="pagination"]');
        const statsEl = this.querySelector('[data-widget="stats"]');
        const hitsContainer = this.querySelector('[data-widget="hits"]');
        const widgets = [getConfigureWidget()];

        searchBoxEl && widgets.push(getSearchWidget(searchBoxEl, postType === 'auteur'  ? 'Rechercher un auteur' : 'Rechercher un auteur, un livre...'));
        collectionsFilterEl && widgets.push(getMenuFilter(collectionsFilterEl, 'collections'));
        thematicsFilterEl && widgets.push(getRefinementList(thematicsFilterEl, 'themes', 'Thèmes'));
        subThematicsFilterEl && widgets.push(getRefinementList(subThematicsFilterEl, 'themes', 'Thèmes'));
        formatsFilterEl && widgets.push(getRefinementList(formatsFilterEl, 'formats', 'Formats'));
        authorsFilterEl && widgets.push(getRefinementList(authorsFilterEl, 'auteurs', 'Auteurs'));
        qualificationsFilterEl && widgets.push(getRefinementList(qualificationsFilterEl, 'qualifications', 'Autres'));
        trainersFilterEl && widgets.push(getMenuFilterWithPanel(trainersFilterEl, 'formateurs', 'Formateurs'));
        hitsContainer && widgets.push(getSearchHits(hitsContainer, postType === 'auteur' ? authorRenderer  : (hit, html) => bookItemRenderer(hit, html, this.defaultCoverUrl)));

        statsEl && widgets.push(getStatsWidget(statsEl, (html, data) => this.statsTextGenerator(html, data, postType)));
        paginationEl && widgets.push(getPagination(this.paginationScrollToEl, paginationEl));
        this.currentRefinementEl && widgets.push(getCurrentRefinementsWidget(this.currentRefinementEl));
        this.currentRefinementElMobile && widgets.push(getCurrentRefinementsWidget(this.currentRefinementElMobile));

        widgets.length && searchInst.addWidgets(widgets);
    }

    clearSearchRefinements() {
        this.search.helper
            .setQuery('')
            .clearRefinements()
            .search();
    }

    getClearRefinementsButton() {
        const li = document.createElement('li');
        const button = document.createElement('button');
        const text = document.createElement('span');

        text.innerText = 'Tout effacer';
        button.appendChild(text);
        button.onclick = () => {
            this.clearSearchRefinements();
        }
        li.setAttribute('class', 'clear-all')
        li.appendChild(button);

        return li;
    }

    listenToCollectionSelect() {
        const items = this.querySelectorAll('div[data-widget="collections"] li.ais-Menu-item.ais-Menu-item--selected');

        items.forEach(el => {
            el.addEventListener('click', () => {
                this.search.helper
                    .clearRefinements('themes')
                    .clearRefinements('formateurs')
                    .search();
            });
        })
    }

    onSearchRender() {
        // Check if we have collection selected
        if (this.search.renderState[this.indexName]) {
            const state = this.search.renderState[this.indexName];
            let hasCollectionFilter = false;

            if (state.currentRefinements && state.currentRefinements.items && state.currentRefinements.items.length) {
                hasCollectionFilter = state.currentRefinements.items.find(item => item.attribute === 'collections')
            }

            setTimeout(() => {
                this.classList.toggle('has-collection-selected', !!hasCollectionFilter);
            }, 250);

            this.listenToCollectionSelect();
        }

        if (this.currentRefinementEl && this.currentRefinementElMobile) {
            const refinementsListEl = [];
            refinementsListEl.push(this.currentRefinementEl.querySelector('ul.ais-CurrentRefinements-list'));
            refinementsListEl.push(this.currentRefinementElMobile.querySelector('ul.ais-CurrentRefinements-list'));

            refinementsListEl.forEach(listEl => {
                if (listEl) {
                    const clearButtonEl = listEl.querySelector('li.clear-all');

                    !clearButtonEl && listEl.appendChild(this.getClearRefinementsButton());
                }
            });
        }
        if (!this.firstRender) {
            this.firstRender = true;
            setTimeout(() => {
                this.initMobileResultSectionObserver();
                this.inputSearch = this.querySelector('input.ais-SearchBox-input');
                this.inputSearch && this.inputSearch.focus();

                this.querySelectorAll('.hide').forEach(el => {
                    el.classList.remove('hide');
                });

                this.querySelectorAll('.loader-wrapper').forEach(el => {
                    el.classList.add('hide');
                })

                // Hide skeletons
                this.querySelectorAll('.skeleton-wrapper').forEach(el => {
                   el.remove();
                });
            }, 2000);
        }
    }

    // On mobile if this section is in the viewport
    // show the "show filters" button
    initMobileResultSectionObserver() {
        const resultContainerEl = this;
        const mobileFilterButtonEl = this.querySelector('.show-filters-button-container');

        this.filtersContainerEl = this.querySelector('.sub-filters');

        if (resultContainerEl && mobileFilterButtonEl && this.filtersContainerEl) {
            const filtersInnerWrapperEl = this.filtersContainerEl.querySelector('.inner-wrapper');
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        mobileFilterButtonEl.classList.add('show');
                    } else {
                        mobileFilterButtonEl.classList.remove('show');
                    }
                });
            }, {threshold: 0.05});
            const closeFiltersContainerEl =  this.filtersContainerEl.querySelector('button.close');
            const showResultButtonContainerEl =  this.filtersContainerEl.querySelector('.apply-filters-button-container > button');

            [
                closeFiltersContainerEl,
                mobileFilterButtonEl,
            ].forEach(item => {
                item && item.addEventListener('click', () => this.onShowFilters());
            })

            showResultButtonContainerEl?.addEventListener('click', this.onShowResult.bind(this))

            observer.observe(resultContainerEl);
            filtersInnerWrapperEl.addEventListener('scroll', () => {
                filtersInnerWrapperEl.classList.toggle('shadowed', !!filtersInnerWrapperEl.scrollTop);
            });
        }
    }

    onShowResult() {
        this.onShowFilters();
        if (this.paginationScrollToEl) {
            this.paginationScrollToEl.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }

    // Show filters on mobile
    onShowFilters(flag) {
        if (this.filtersContainerEl) {
            if (flag === undefined) {
                this.filtersContainerEl.classList.toggle('show');
                $('html, body').toggleClass('open');
            } else {
                this.filtersContainerEl.classList.toggle('show', flag);
                $('html, body').toggleClass('open', flag);
            }
        }
    }

    // Build routing
    buildRouting(collectionsString, indexName, basePath) {
        try {
            const collections = JSON.parse(collectionsString);
            if (collections && collections.length) {
                return getRouting(indexName, collections, basePath);
            }
        } catch (e) {
            console.warn('Parse thematics error: ', e);
        }

        return true;
    }

    onWindowResize() {
        if (window.innerWidth >= 920) {
            this.onShowFilters(false);
        }
    }

    buildInstantSearch(appId, appKey, indexName, routing, postType) {
        try {
            const algoliaClient = window.algoliasearch(
                appId,
                appKey
            );
            const searchClient = {
                ...algoliaClient
            };

            this.search = window.instantsearch({
                searchClient,
                indexName,
                insights: true,
                routing
            });

            const syncMiddleware = () => {
                return {
                    onStateChange({ uiState }) {
                        console.log('--- UI STATE --', uiState);
                    }
                };
            };

            this.search.use(syncMiddleware);
            this.indexName = indexName;
            this.currentRefinementEl = this.querySelector('.results-container div[data-widget="current-refinements"]');
            this.currentRefinementElMobile = this.querySelector('.sub-filters div[data-widget="current-refinements"]');
            this.buildWidgets(this.search, postType);

            this.search.on('render', this.onSearchRender.bind(this));
            this.search.start();

            initHeaderHeightUpdater(() => { this.onWindowResize(); });
            handleWindowResize();
            this.onWindowResize();
        } catch (e) {
            console.warn('Error while initializing instantsearch', e);
        }
    }

    /**
     * Initialisation
     */
    init() {
        const {
            appId,
            appKey,
            indexName,
            basePath,
            collections,
            postType,
            coverUrl = ''
        } = this.dataset;

        const routing = this.buildRouting(collections, indexName, (basePath || ''));

        this.paginationScrollToEl = this.querySelector('#scroll-top-mark');
        this.defaultCoverUrl = coverUrl;
        appId && appKey && indexName && this.buildInstantSearch(appId, appKey, indexName, routing, postType);
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        })
    }
}
